import { useState } from 'react';
import { useSpring } from 'react-spring';

const useSidebar = () => {
  const [isVisible, setVisible] = useState(false);

  const sidebarAnimation = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? `translateX(0)` : `translateX(-100%)`,
  });

  const handleToggle = () => setVisible(!isVisible);

  return {
    isVisible,
    handleToggle,
    sidebarAnimation,
  };
};

export default useSidebar;
