import { VFC } from 'react';
import DuoCube from '../../../../models/DuoCube';
import SingleCube from '../../../../models/SingleCube';
import KidsCube from '../../../../models/KidsCube';
import TrioCube from '../../../../models/TrioCube';
import GrilCube from '../../../../models/GrilCube';

type ConfigModelProps = {
  slug: string;
};

const ConfigModel: VFC<ConfigModelProps> = ({ slug }: ConfigModelProps) => {
  switch (slug) {
    case 'single':
      return <SingleCube />;
    case 'duo':
      return <DuoCube />;
    case 'trio':
      return <TrioCube />;
    case 'kids':
      return <KidsCube />;
    case 'gril':
      return <GrilCube />;
    case 'create':
      return <></>;
    default:
      return <SingleCube />;
  }
};

export default ConfigModel;
