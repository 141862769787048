import { VFC } from 'react';
import styled from 'styled-components';
import MostPopularSection from './MostPopularSection';
import OfferItem from './OfferItem';
import CubeImg from '../../assets/cubo-base.jpg';
import CubeImg2 from '../../assets/conf.png';

const OfferPage: VFC = () => (
  <Container>
    <Title>figuruj</Title>
    <Content>
      <OfferItem
        width="45%"
        height="inherit"
        image={CubeImg}
        main
        data={{
          name: 'Cubo Single',
          id: 'single',
          description:
            'Cubo Single® to najpopularniejszy model Cubo oglądany i wybierany w naszym serwisie, ujmuje on swoim minimalizmem i prostotą.',
        }}
      />
      <OfferItem
        width="45%"
        height="inherit"
        image={CubeImg2}
        main
        data={{
          name: 'Create Cubo',
          id: 'custom',
          description:
            'Wykreuj już dziś swoją strukturę Cubo. Dodawaj kolejne elementy i zapisuj konfigurację w swoim koncie klienta.',
        }}
      />
    </Content>
    <MostPopularSection />
  </Container>
);

export default OfferPage;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px 0;
`;

const Title = styled.h1`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 47px;
  padding-left: 65px;
  color: #000000;

  &::before {
    content: 'Kon';
    position: absolute;
    z-index: -1;
    text-align: right;
    color: #ffffff;
    background-color: #000000;
    width: 80px;
    height: 47px;
    top: 0;
    left: -15px;
  }
`;
