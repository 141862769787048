import { ReactNode, VFC } from 'react';
import styled from 'styled-components';
import { BsArrowRightShort } from 'react-icons/bs';

type Props = {
  onClick?: () => void;
  children: ReactNode;
  icon?: boolean;
};

const Button: VFC<Props> = ({ onClick, children, icon }: Props) => (
  <Container onClick={onClick}>
    {children} {icon && <BsArrowRightShort size={16} />}
  </Container>
);

const Container = styled.button`
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: inherit;
  font-size: 15px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  & > a {
    color: #000000;
  }
`;

export const ConfigButton = styled.button<{ outlined?: boolean }>`
  border: 1px solid #000000;
  background-color: ${({ outlined }) => (outlined ? '#ffffff' : '#000000')};
  color: ${({ outlined }) => (!outlined ? '#ffffff' : '#000000')};
  font-size: 16px;
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ outlined }) => (!outlined ? '#ffffff' : '#000000')};
    color: ${({ outlined }) => (outlined ? '#ffffff' : '#000000')};
  }
`;

export default Button;
