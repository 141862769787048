/* eslint-disable react/no-array-index-key */
import { VFC, useRef, useState, useEffect } from 'react';
import _ from 'lodash-es';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { useLoader } from '@react-three/fiber';
import daeFile from '../assets/dae/left.dae';

type LeftCubeProps = {
  length: number;
};

const LeftCube: VFC<LeftCubeProps> = ({ length }: LeftCubeProps) => {
  const [state, setState] = useState<string[]>([]);

  useEffect(() => {
    const fileList: string[] = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      fileList.push(daeFile);
    }

    setState(fileList);
  }, [length]);

  const ref = useRef();
  const collada = useLoader(ColladaLoader, state);

  return (
    <>
      {collada.map((data, index) => (
        <group
          key={index}
          position={[
            -4.738,
            -1,
            index === 0 ? index + 4.525 : index * 2.3 + 4.52,
          ]}
        >
          <primitive object={data.scene as any} name={index} ref={ref} />
        </group>
      ))}
    </>
  );
};

export default LeftCube;
