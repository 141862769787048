/* eslint-disable @typescript-eslint/no-explicit-any */
import { VFC, useRef } from 'react';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { useLoader } from '@react-three/fiber';
import daeFile from '../assets/dae/duo.dae';

const DuoCube: VFC = () => {
  const { scene } = useLoader(ColladaLoader, daeFile) as any;
  const group = useRef();

  return (
    <>
      <group ref={group} position={[-5, -1, 3.5]}>
        <primitive dispose={null} object={scene} />
      </group>
    </>
  );
};

export default DuoCube;
