/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { useLocation } from 'react-router-dom';

type Params = {
  public_id: string;
  preview: string;
  right: string;
  left: string;
};

export const useParams = (): Partial<Params> => {
  const location = useLocation();
  return location.search !== ''
    ? location.search
        .slice(1)
        .split('&')
        .map((q) => q.split('='))
        .reduce((a, c) => {
          a[c[0]] = c[1];
          return a;
        }, {} as any)
    : undefined;
};
