import { VFC, Suspense } from 'react';
import styled from 'styled-components';
import { IoIosCall, IoIosMenu } from 'react-icons/io';
import { BiPurchaseTag } from 'react-icons/bi';
import Scene from '../Scene';
import Sidebar from './Sidebar';
import ConfigModel from './ConfigModel';
import ConfigGallery from './ConfigGallery';
import Loader from '../../shared/Loader';
import { useConfigPage } from './ConfigPage.hook';
import PurchaseModal from './PurchaseModal';
import ConfigCreator from './ConfigCreator';
import InfoPage from './InfoPage';

const ConfigPage: VFC = () => {
  const {
    slug,
    cubes,
    gallery,
    isCustom,
    isLogged,
    isPreview,
    handleToggle,
    handleAddLeft,
    handleAddRight,
    sidebarAnimation,
    handleOpenGallery,
    handleCloseGallery,
    openPurchaseModal,
    handleOpenPurchaseModal,
    handleClosePurchaseModal,
  } = useConfigPage();
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Container>
          <Sidebar onOpenGallery={handleOpenGallery} style={sidebarAnimation} />
          <IconWrapper style={{ top: 220 }}>
            <IoIosCall color="#ffffff" />
          </IconWrapper>
          {!isPreview && (
            <IconWrapper style={{ top: 100 }} onClick={handleToggle}>
              <IoIosMenu color="#ffffff" />
            </IconWrapper>
          )}
          {isLogged ? (
            <IconWrapper style={{ top: 160 }} onClick={handleOpenPurchaseModal}>
              <BiPurchaseTag color="#ffffff" />
            </IconWrapper>
          ) : (
            <IconWrapper
              style={{ top: 160 }}
              onClick={() => {
                if (
                  window.confirm(
                    'Aby złożyć zamówienie musisz być zalogowany! nastąpi przekierowanie na stronę logowania.',
                  )
                ) {
                  window.location.href =
                    'https://cubo.qropqa.com/clients/sign_in';
                }
              }}
            >
              <BiPurchaseTag color="#ffffff" />
            </IconWrapper>
          )}

          {isCustom ? (
            <ConfigCreator
              handleAddLeft={handleAddLeft}
              handleAddRight={handleAddRight}
              isPreview={isPreview}
              cubes={cubes}
            />
          ) : (
            <>
              <Scene>
                <ConfigModel slug={slug} />
              </Scene>
              <InfoPage slug={slug} />
            </>
          )}
        </Container>
      </Suspense>
      <ConfigGallery onClose={handleCloseGallery} isOpen={gallery} />
      <PurchaseModal
        cubes={cubes}
        isOpen={openPurchaseModal}
        onClose={handleClosePurchaseModal}
      />
    </>
  );
};

export default ConfigPage;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 100px;
  height: 50px;
  width: 50px;
  z-index: 10;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #000000;
  cursor: pointer;
`;
