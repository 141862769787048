import { OrbitControls, Plane } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { FC, ReactNode } from 'react';
import * as THREE from 'three';

type Props = {
  children: ReactNode;
};

const Scene: FC<Props> = ({ children }: Props) => (
  <Canvas
    style={{ width: '100%', height: '100vh', backgroundColor: '#FFFFFF' }}
    shadows
    camera={{ position: [10, 0, 0], fov: 50 }}
  >
    <OrbitControls
      enableZoom
      enableRotate
      // minPolarAngle={Math.PI / 2}
      // maxPolarAngle={Math.PI / 2}
      position={[0, 10, 0]}
    />
    {/* <primitive object={new THREE.AxesHelper(10)} /> */}
    <fog attach="fog" args={['white', 0, 40]} />
    <ambientLight intensity={0.1} />
    <directionalLight
      intensity={0.5}
      castShadow
      shadow-mapSize-height={512}
      shadow-mapSize-width={512}
    />
    <Plane
      receiveShadow
      rotation={[-Math.PI / 2, 0, 0]}
      position={[0, -2, 0]}
      args={[1000, 1000]}
    >
      <meshStandardMaterial attach="material" color="white" />
    </Plane>
    {children}
  </Canvas>
);

export default Scene;
