/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useRef, VFC } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import single from '../../../../assets/specification/kat_single.pdf';
import duo from '../../../../assets/specification/kat_duo.pdf';
import trio from '../../../../assets/specification/kat_trio.pdf';
import kids from '../../../../assets/specification/kat_kids.pdf';
import gril from '../../../../assets/specification/kat_gril.pdf';
import Modal from '../../../Modal';

type DownloadCatModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const files: any = {
  single,
  duo,
  trio,
  kids,
  gril,
};

const DownloadCatModal: VFC<DownloadCatModalProps> = ({
  onClose,
  isOpen,
}: DownloadCatModalProps) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        onClose();
        link.current.click();
      }, 1500);
    }
  }, [onClose, isOpen]);

  const location = useLocation();
  const cubo_type = location.pathname.substring(1);

  const link = useRef<any>();

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Title>Pobieranie...</Title>
      <Content>
        <Loader type="ThreeDots" color="#000000" height={80} width={80} />
        <a
          ref={link}
          href={files[cubo_type]}
          download={`Katalog produktów Cubo ${cubo_type}.pdf`}
        />
      </Content>
    </Modal>
  );
};

export default DownloadCatModal;

const Title = styled.h2`
  margin: 0;
`;

const Content = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;
`;
