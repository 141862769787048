import { motion } from 'framer-motion';
import { VFC } from 'react';
import styled from 'styled-components';

const Loader: VFC = () => {
  return (
    <Container>
      <StyledDiv
        animate={{
          scale: [1, 2, 2, 1, 1],
          rotate: [0, 0, 270, 270, 0],
          borderRadius: ['20%', '20%', '50%', '50%', '20%'],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          times: [0, 0.2, 0.5, 0.8, 1],
          loop: Infinity,
          repeatDelay: 1,
        }}
      />
    </Container>
  );
};

export default Loader;

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDiv = styled(motion.div)`
  background: white;
  border-radius: 30px;
  width: 150px;
  height: 150px;
`;
