/* eslint-disable react/no-array-index-key */
import React, { VFC } from 'react';
import styled from 'styled-components';
import _ from 'lodash-es';
import LeftCube from '../../../../models/LeftCube';
import RightCube from '../../../../models/RightCube';
import SingleCube from '../../../../models/SingleCube';
import { ConfigButton } from '../../../shared/Button';
import Scene from '../../Scene';
import { CubeList } from '../ConfigPage.hook';

type ConfigCreatorProps = {
  cubes: CubeList;
  isPreview: boolean;
  handleAddLeft: () => void;
  handleAddRight: () => void;
};

const ConfigCreator: VFC<ConfigCreatorProps> = ({
  cubes,
  isPreview,
  handleAddLeft,
  handleAddRight,
}: ConfigCreatorProps) => {
  return (
    <>
      {!isPreview && (
        <Rounded>
          <h3 style={{ marginBottom: 10, marginTop: 0 }}>
            Utwórz model od zera, dodaj element:
          </h3>
          <BtnWrapper>
            <ConfigButton type="button" onClick={handleAddLeft}>
              Z lewej
            </ConfigButton>
            <ConfigButton type="button" onClick={handleAddRight}>
              Z prawej
            </ConfigButton>
          </BtnWrapper>
        </Rounded>
      )}

      {!isPreview && (
        // <CostWrapper>
        //   <h3>Lista elementów:</h3>
        //   {_.times(cubes.left, (idx) => (
        //     <CostRow key={idx}>Model LEFT</CostRow>
        //   ))}
        //   {_.times(cubes.right, (idx) => (
        //     <CostRow key={idx}>Model RIGHT</CostRow>
        //   ))}
        // </CostWrapper>

        <CostWrapper>
          <h3>Lista elementów:</h3>
          <CostLi>
            <ul>
              <li>
                <strong>Rzut struktury: </strong>
                220 cm x 220 cm,
              </li>
              <li>
                <strong>Wysokość struktury: </strong>
                240 cm,
              </li>
              <li>
                <strong>Przekątna: </strong> 311 cm,
              </li>
              <li>
                <strong>Typ drewna: </strong>C24 – iglaste,
              </li>
              <li>
                <strong>Ilość modułów głównych: </strong>4 szt,
              </li>
              <li>
                <strong>Ilość modułów legarowych: </strong>4 szt,
              </li>
              <li>
                <strong>Ilość modułów łącznikowych:</strong> 6 szt,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 1: </strong> dach A,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 2: </strong> podłoga V,
              </li>
            </ul>
          </CostLi>
        </CostWrapper>
      )}

      <Scene>
        <LeftCube length={cubes.left} />
        <RightCube length={cubes.right} />
        <SingleCube />
      </Scene>
    </>
  );
};

export default ConfigCreator;

const Rounded = styled.div`
  border-radius: 1rem 1rem 1rem 0;
  background-color: #ffffff;
  position: fixed;
  z-index: 10000;
  bottom: 20px;
  left: 380px;
  padding: 1rem;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const CostWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 1rem 0 0 0;
  height: 50vh;
  position: fixed;
  bottom: 20px;
  width: 20rem;
  right: 20px;
  z-index: 10000;
  overflow-y: auto;
  padding: 1rem;
`;

// const CostRow = styled.div`
//   margin-bottom: 10px;
//   font-weight: 600;
// `;

const CostLi = styled.div`
  margin-bottom: 30px;
  line-height: 35px;
`;
