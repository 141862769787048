/* eslint-disable react/jsx-props-no-spreading */
import { VFC } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import Modal from '../../../Modal';
import { ConfigButton } from '../../../shared/Button';
import { usePurchaseModal } from './PurchaseModal.hook';
import { CubeList } from '../ConfigPage.hook';

type PurchaseModalProps = {
  cubes?: CubeList;
  isOpen: boolean;
  onClose: () => void;
};

const PurchaseModal: VFC<PurchaseModalProps> = ({
  onClose,
  isOpen,
  cubes,
}: PurchaseModalProps) => {
  const { crateOrder, isLoading, isError } = usePurchaseModal({
    cubes,
    onClose,
  });
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Title>Czy chcesz zamówić ten model?</Title>
      <Content>
        {isLoading && (
          <Content2>
            <Loader type="ThreeDots" color="#000000" height={80} width={80} />
            <h2>Trwa procesowanie zamówienia.</h2>
          </Content2>
        )}

        {isError && <p>Wystąpił błąd podczas zamawiania modelu</p>}
      </Content>
      <Footer>
        <ConfigButton disabled={isLoading} onClick={onClose} outlined>
          Anuluj
        </ConfigButton>
        <ConfigButton onClick={crateOrder} disabled={isLoading}>
          Kup
        </ConfigButton>
      </Footer>
    </Modal>
  );
};

export default PurchaseModal;

const Title = styled.h2`
  margin: 0;
`;

const Content = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
