/* eslint-disable react/no-unused-prop-types */
import { VFC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { OfferBrief } from '../../shared/types/offers';
import Button from '../shared/Button';
import { ItemTitle } from '../shared/SectionTitle';

interface Container {
  width?: string;
  height?: string;
  main?: boolean;
}

interface Props extends Container {
  data: OfferBrief;
  image?: string;
}

const OfferItem: VFC<Props> = ({
  data: { name, description, id },
  image,
  width,
  height,
  main,
}: Props) => {
  const history = useHistory();
  const { search } = history.location;
  const handleGoTo = () => history.push(`/${id}${search}`);

  return (
    <Container main={main} height={height} width={width}>
      <ItemTitle>{name}</ItemTitle>
      {image && <Image src={image} alt="cube" />}
      <p>
        {description ||
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed semper ligula a euismod fermentum. Donec varius elit nec malesuada elementum.'}
      </p>
      <button type="button" onClick={handleGoTo}>
        Więcej
      </button>
      <span className="left" />
      <span className="right" />
      <span className="top" />
      <span className="bottom" />
    </Container>
  );
};
export default OfferItem;

const Container = styled.div<Container>`
  width: ${({ width }) => width || '320px'};
  height: ${({ height }) => height || '200px'};
  margin: 20px 0;
  position: relative;
  z-index: 102;
  padding-left: 8px;
  padding-bottom: ${({ main }) => main && '30px'};
  box-sizing: border-box;
  background-color: #f4f4f4;
  box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.25);

  & > span {
    width: 1px;
    height: 100%;
    z-index: 1000;
    background-color: #000000;
    position: absolute;
  }

  & > .left {
    left: -15px;
    bottom: 15px;
  }

  & > .right {
    right: 15px;
    bottom: 15px;
  }

  & > .bottom {
    width: 100%;
    height: 1px;
    right: 15px;
    bottom: 15px;
  }

  & > .top {
    width: 100%;
    height: 1px;
    right: 15px;
    top: -15px;
  }
  & > p {
    max-width: 90%;
  }

  /* &::before {
    content: '';
    z-index: 101;
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    border: 1px solid #000000;
    left: -15px;
    top: -15px;
  } */
`;

const Image = styled.img`
  width: 50%;
  height: auto;
  max-width: 200px;
`;
