import { VFC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AiFillFilePdf } from 'react-icons/ai';
import { RiFolderUploadFill } from 'react-icons/ri';
import { ItemTitle } from '../../../../shared/SectionTitle';

type Props = {
  id: string;
  name: string;
  image?: string;
  description?: string;
  onDownloadSpec?: () => void;
  onDownloadCat?: () => void;
};

const MenuItem: VFC<Props> = ({
  onDownloadSpec,
  onDownloadCat,
  image,
  name,
  id,
}: Props) => {
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();
  const { search } = history.location;
  const handleGoTo = () => history.push(`/${id}${search}`);

  return (
    <Container onClick={handleGoTo} isActive={slug === id}>
      {image && <Image src={image} />}
      <Wrapper>
        <ItemTitle>{name}</ItemTitle>
        <div>
          {onDownloadCat && (
            <RiFolderUploadFill
              color="#000000"
              style={{ marginRight: 10 }}
              size={26}
              onClick={onDownloadCat}
            />
          )}
          {onDownloadSpec && (
            <AiFillFilePdf color="#000000" size={26} onClick={onDownloadSpec} />
          )}
        </div>
      </Wrapper>
    </Container>
  );
};
export default MenuItem;

const Container = styled.li<{ isActive: boolean }>`
  width: 90%;
  position: relative;
  margin-bottom: 25px;
  padding: 10px 0 0 10px;
  z-index: 102;
  background-color: #ffffff;
  opacity: ${({ isActive }) => (isActive ? 0.8 : 1)};

  &:hover {
    cursor: pointer;
    opacity: 0.8;

    &::before {
      opacity: 0.8;
    }
  }

  &::before {
    content: '';
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    border: 1px solid #ffffff;
    left: -15px;
    top: -15px;
    opacity: ${({ isActive }) => (isActive ? 0.8 : 1)};
  }

  &::after {
    content: '';
  }
`;

const Image = styled.img`
  width: 40%;
  height: auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;
