import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SingleM1F from '../../../../assets/images/single/M1F.jpg';
import SingleM1POP from '../../../../assets/images/single/M1POP.jpg';
import SingleM1E from '../../../../assets/images/single/M1E.jpg';
import DUOM2POP from '../../../../assets/images/duo/M2POP.jpg';
import M3POP from '../../../../assets/images/kids/M3POP.jpg';
import M4POP from '../../../../assets/images/trio/M4POP.jpg';
import M5POP from '../../../../assets/images/gril/M5POP.jpg';

type CubeTypes = 'single' | 'duo' | 'trio' | 'gril' | 'kids';

type Images = {
  [key in CubeTypes]: string[];
};

const images: Images = {
  single: [SingleM1POP, SingleM1E, SingleM1F],
  duo: [DUOM2POP],
  kids: [M3POP],
  trio: [M4POP],
  gril: [M5POP],
};

export const useConfigGallery = () => {
  const { slug } = useParams<{ slug: string }>();
  const [index, setIndex] = useState<number>(0);

  const photos: string[] = images[`${slug}` as 'single'];

  const onMovePrev = () => {
    setIndex((index + photos.length - 1) % photos.length);
  };

  const onMoveNext = () => {
    setIndex((index + 1) % photos.length);
  };

  return { index, photos, onMovePrev, onMoveNext };
};
