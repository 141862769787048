import styled from 'styled-components';

export const ItemTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  z-index: 123;
  margin: 0;
  padding: 10px 0;
`;

type SectionTitle = {
  section: string;
};

export const SectionTitle = styled.h1<SectionTitle>`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  padding-left: 65px;
  color: #000000;

  &::before {
    content: ${({ section }) => `${section}`};
    position: absolute;
    z-index: -1;
    text-align: right;
    color: #ffffff;
    background-color: #000000;
    width: 80px;
    height: 47px;
    top: 0;
    left: -15px;
  }
`;
