/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from '../../../../hooks/params';
import userApi from '../../../../shared/userApi';
import { CubeList } from '../ConfigPage.hook';

type PurchaseModalProps = {
  cubes?: CubeList;
  onClose: () => void;
};

export const usePurchaseModal = ({ cubes, onClose }: PurchaseModalProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const params = useParams();
  const location = useLocation();

  const cubo_type = location.pathname.substring(1);

  useEffect(() => {
    return () => {
      setError(false);
    };
  }, []);

  const crateOrder = async () => {
    if (params && params.public_id) {
      setError(false);
      setLoading(true);
      try {
        const reqBody = cubes && {
          custom_data: `"{ left: ${cubes.left}, right: ${cubes.right} }"`,
        };
        const response = await userApi.post('/api/orders', {
          order: { cubo_type, ...reqBody },
          public_id: params.public_id,
        });
        console.log(response);
        setTimeout(() => {
          onClose();
          window.location.href = 'https://cubo.qropqa.com/clients/orders';
        }, 3000);
        setError(false);
      } catch (e) {
        setError(true);
      }
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  return {
    crateOrder,
    isLoading,
    isError,
  };
};
