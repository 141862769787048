import { useMemo, VFC } from 'react';
import styled from 'styled-components';
import OfferItem from './OfferItem';

const CUBA_OPTIONS = [
  {
    name: 'Cubo Duo – Twice more!',
    id: 'duo',
    description:
      'Cubo Single® to najpopularniejszy model Cubo oglądany i wybierany w naszym serwisie, ujmuje on swoim minimalizmem i prostotą.',
  },
  {
    name: 'Cubo Trio Arbor!',
    id: 'trio',
    description:
      'Cubo Trio® Arbor to kolejna konfiguracja Click&Link tym razem przeznaczona dla osób lubiących ciszę, spokój i relaks.',
  },
  {
    name: 'Cubo Trio KIDS!',
    id: 'kids',
    description:
      'Cubo Trio® KIDS to zestaw, skonfigurowany w celu zapewnienia dla najmłodszych największej liczby atrakcji.',
  },
  {
    name: 'Cubo Trio Grill&Fun!',
    id: 'gril',
    description:
      'Cubo Trio® Grill&Fun imprezy ogrodowe są ulubione przez Ciebie? Jeśli tak to ta konfiguracja jest dla Ciebie!',
  },
];

const MostPopularSection: VFC = () => (
  <>
    <Title>popularniejsze</Title>
    <Wrapper>
      {useMemo(
        () =>
          CUBA_OPTIONS.map((offer) => (
            <OfferItem key={offer.id} data={offer} />
          )),
        [],
      )}
    </Wrapper>
  </>
);

export default MostPopularSection;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 47px;
  padding-left: 65px;
  color: #000000;

  &::before {
    content: 'Naj';
    position: absolute;
    z-index: -1;
    text-align: right;
    color: #ffffff;
    background-color: #000000;
    width: 80px;
    height: 47px;
    top: 0;
    left: -15px;
  }
`;
