import { VFC } from 'react';
import Lightbox from 'react-image-lightbox';
import { useConfigGallery } from './ConfigGallery.hook';
import 'react-image-lightbox/style.css';

type ConfigGalleryProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ConfigGallery: VFC<ConfigGalleryProps> = ({
  isOpen,
  onClose,
}: ConfigGalleryProps) => {
  const { index, photos, onMovePrev, onMoveNext } = useConfigGallery();

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={photos[index]}
          nextSrc={photos[(index + 1) % photos.length]}
          prevSrc={photos[(index + photos.length - 1) % photos.length]}
          onCloseRequest={onClose}
          onMovePrevRequest={onMovePrev}
          onMoveNextRequest={onMoveNext}
        />
      )}
    </>
  );
};

export default ConfigGallery;
