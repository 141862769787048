import { useCallback, useEffect, useState } from 'react';
import { useParams as useRouteParams } from 'react-router-dom';
import { useParams } from '../../../hooks/params';
import useSidebar from '../../../hooks/useSidebar';

export type CubeList = {
  left: number;
  right: number;
};

export const useConfigPage = () => {
  const [gallery, setGallery] = useState<boolean>(false);
  const [openPurchaseModal, setPurchaseModal] = useState<boolean>(false);
  const { handleToggle, sidebarAnimation } = useSidebar();
  const [cubes, setCubes] = useState<CubeList>({
    left: 0,
    right: 0,
  });

  const params = useParams();

  useEffect(() => {
    if (params && params.left && params.right && params.preview) {
      setCubes({ left: Number(params.left), right: Number(params.right) });
    }
  }, [params]);

  const handleAddLeft = useCallback(() => {
    setCubes({
      ...cubes,
      left: cubes.left + 1,
    });
  }, [cubes]);

  const handleAddRight = useCallback(() => {
    setCubes({
      ...cubes,
      right: cubes.right + 1,
    });
  }, [cubes]);

  const { slug } = useRouteParams<{ slug: string }>();

  const isPreview = !!(params && params.preview);
  const isLogged = !!(params && params.public_id);

  useEffect(() => {
    if (isPreview) {
      return;
    }
    handleToggle();
  }, [isPreview]);

  return {
    slug,
    cubes,
    gallery,
    isLogged,
    isPreview,
    handleToggle,
    handleAddLeft,
    handleAddRight,
    sidebarAnimation,
    openPurchaseModal,
    isCustom: slug === 'custom',
    handleOpenPurchaseModal: () => setPurchaseModal(true),
    handleClosePurchaseModal: () => setPurchaseModal(false),
    handleOpenGallery: () => setGallery(true),
    handleCloseGallery: () => setGallery(false),
  };
};
