import { createGlobalStyle } from 'styled-components';
/*
  GlobalStyles to manage
*/
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.textColors.primary};
    transition: all 0.50s linear;
    font-family: 'Roboto';
    min-height: 100vh;
    padding: 0px;
    margin: 0px;
  }
`;
