/* eslint-disable @typescript-eslint/ban-types */
import { VFC, useState, useCallback } from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { HiPhotograph, HiOutlineFolderDownload } from 'react-icons/hi';
import MenuItem from './MenuItem/MenuItem';
import M1F from '../../../../assets/images/sidebar/M1F_pop.jpg';
import M2F from '../../../../assets/images/sidebar/M2F_pop.jpg';
import M3F from '../../../../assets/images/sidebar/M3F_pop.jpg';
import M4F from '../../../../assets/images/sidebar/M4F_pop.jpg';
import M5F from '../../../../assets/images/sidebar/M5F_pop.jpg';
import DownloadModal from '../DownloadModal';
import DownloadCatModal from '../DownloadCatModal';

type Props = {
  onOpenGallery: () => void;
  style: object;
};

const CUBA_OPTIONS = [
  {
    name: 'Cubo Single',
    id: 'single',
    image: M1F,
  },
  {
    name: 'Cubo Duo',
    id: 'duo',
    image: M2F,
  },
  {
    name: 'Cubo Kids',
    id: 'kids',
    image: M3F,
  },
  {
    name: 'Cubo Trio',
    id: 'trio',
    image: M4F,
  },
  {
    name: 'Cubo Gril',
    id: 'gril',
    image: M5F,
  },
];

const Sidebar: VFC<Props> = ({ onOpenGallery, style }: Props) => {
  const [openDownloadModal, setDownloadModal] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => {
    setDownloadModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setDownloadModal(false);
  }, []);

  const [openDownloadCatModal, setDownloadCatModal] = useState<boolean>(false);

  const handleOpenCatModal = useCallback(() => {
    setDownloadCatModal(true);
  }, []);

  const handleCloseCatModal = useCallback(() => {
    setDownloadCatModal(false);
  }, []);

  return (
    <>
      <AnimatedDiv style={style}>
        <nav>
          <Menu>
            <MenuItem id="custom" name="Utwórz od zera" />
            {CUBA_OPTIONS.map((cuba) => (
              <MenuItem
                key={cuba.id}
                id={cuba.id}
                name={cuba.name}
                image={cuba.image}
                onDownloadSpec={handleOpenModal}
                onDownloadCat={handleOpenCatModal}
              />
            ))}
          </Menu>
          <Icons>
            {/* <HiOutlineFolderDownload fontSize={30} color="#ffffff" /> */}
            <HiPhotograph
              onClick={onOpenGallery}
              fontSize={30}
              color="#ffffff"
            />
          </Icons>
        </nav>
      </AnimatedDiv>
      <DownloadModal onClose={handleCloseModal} isOpen={openDownloadModal} />
      <DownloadCatModal
        onClose={handleCloseCatModal}
        isOpen={openDownloadCatModal}
      />
    </>
  );
};

export default Sidebar;

const AnimatedDiv = styled(animated.div)`
  position: absolute;
  background-color: #000000;
  color: #fff;
  z-index: 30;
  top: 0;
  left: 0;
  width: 360px;
  height: 100vh;
`;

const Menu = styled.ul`
  padding: 0;
  list-style: none;
  margin: 24px;
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  & > * {
    cursor: pointer;
  }
`;
