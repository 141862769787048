import { VFC } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ConfigPage from './components/Configurator/ConfigPage';
import NotFoundPage from './components/NotFoundPage';
import OfferPage from './components/OfferPage/OfferPage';
import GlobalThemeProvider from './shared/globalStyles/provider';

const App: VFC = () => (
  <GlobalThemeProvider>
    <Router>
      <Switch>
        <Route exact path="/:slug" component={ConfigPage} />
        <Route exact path="/" component={OfferPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  </GlobalThemeProvider>
);

export default App;
