import { VFC } from 'react';
import styled from 'styled-components';
import DuoCube from '../../../../models/DuoCube';
import SingleCube from '../../../../models/SingleCube';
import KidsCube from '../../../../models/KidsCube';
import TrioCube from '../../../../models/TrioCube';
import GrilCube from '../../../../models/GrilCube';

type InfoPageProps = {
  slug: string;
};

const ConfigModel: VFC<InfoPageProps> = ({ slug }: InfoPageProps) => {
  switch (slug) {
    case 'single':
      return (
        <CostWrapper>
          <h3>Lista elementów:</h3>
          <CostLi>
            <ul>
              <li>
                <strong>Rzut struktury: </strong>
                220 cm x 220 cm,
              </li>
              <li>
                <strong>Wysokość struktury: </strong>
                240 cm,
              </li>
              <li>
                <strong>Przekątna: </strong> 311 cm,
              </li>
              <li>
                <strong>Typ drewna: </strong>C24 – iglaste,
              </li>
              <li>
                <strong>Ilość modułów głównych: </strong>4 szt,
              </li>
              <li>
                <strong>Ilość modułów legarowych: </strong>4 szt,
              </li>
              <li>
                <strong>Ilość modułów łącznikowych:</strong> 6 szt,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 1: </strong> dach A,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 2: </strong> podłoga V,
              </li>
            </ul>
          </CostLi>
          <h4>CENA KOMPLETU: 18 000,00 zł brutto</h4>
        </CostWrapper>
      );
    case 'duo':
      return (
        <CostWrapper>
          <h3>Lista elementów:</h3>
          <CostLi>
            <ul>
              <li>
                <strong>Rzut struktury: </strong>
                220 cm x 430 cm,
              </li>
              <li>
                <strong>Wysokość struktury: </strong>
                240 cm,
              </li>
              <li>
                <strong>Przekątna: </strong> 483,1 cm,
              </li>
              <li>
                <strong>Typ drewna: </strong>C24 – iglaste,
              </li>
              <li>
                <strong>Ilość modułów głównych: </strong>6 szt,
              </li>
              <li>
                <strong>Ilość modułów legarowych: </strong>6 szt,
              </li>
              <li>
                <strong>Ilość modułów łącznikowych: </strong>10 szt,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 1: </strong> 2x dach A,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 2: </strong> 1x podłoga V,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 3: </strong> 1x piaskownica,
              </li>
            </ul>
          </CostLi>
          <h4>CENA KOMPLETU: 28 000,00 zł brutto</h4>
        </CostWrapper>
      );
    case 'kids':
      return (
        <CostWrapper>
          <h3>Lista elementów:</h3>
          <CostLi>
            <ul>
              <li>
                <strong>Rzut struktury: </strong>
                220 cm x 640 cm,
              </li>
              <li>
                <strong>Wysokość struktury: </strong>
                240 cm,
              </li>
              <li>
                <strong>Przekątna: </strong> 676,75 cm,
              </li>
              <li>
                <strong>Typ drewna: </strong>C24 – iglaste,
              </li>
              <li>
                <strong>Ilość modułów głównych: </strong>8 szt,
              </li>
              <li>
                <strong>Ilość modułów legarowych: </strong>8 szt,
              </li>
              <li>
                <strong>Ilość modułów łącznikowych: </strong>12 szt,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 1: </strong> 1x dach A,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 2: </strong> 1x dach B,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 3: </strong> 1x podłoga V,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 4: </strong> 1x piaskownica,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 5: </strong> 1x Huśtawka, liny,
              </li>
            </ul>
          </CostLi>
          <h4>CENA KOMPLETU: 39 000,00 zł brutto</h4>
        </CostWrapper>
      );
    case 'trio':
      return (
        <CostWrapper>
          <h3>Lista elementów:</h3>
          <CostLi>
            <ul>
              <li>
                <strong>Rzut struktury: </strong>
                220 cm x 640 cm,
              </li>
              <li>
                <strong>Wysokość struktury: </strong>
                240 cm,
              </li>
              <li>
                <strong>Przekątna: </strong> 676,75 cm,
              </li>
              <li>
                <strong>Typ drewna: </strong>C24 – iglaste,
              </li>
              <li>
                <strong>Ilość modułów głównych: </strong>8 szt,
              </li>
              <li>
                <strong>Ilość modułów legarowych: </strong>8 szt,
              </li>
              <li>
                <strong>Ilość modułów łącznikowych: </strong>18 szt,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 1: </strong> 3x dach B,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 2: </strong> 3x podłoga V,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 3: </strong> 3x s.diagonalna,
              </li>
            </ul>
          </CostLi>
          <h4>CENA KOMPLETU: 36 000,00 zł brutto</h4>
        </CostWrapper>
      );
    case 'gril':
      return (
        <CostWrapper>
          <h3>Lista elementów:</h3>
          <CostLi>
            <ul>
              <li>
                <strong>Rzut struktury: </strong>
                220 cm x 640 cm,
              </li>
              <li>
                <strong>Wysokość struktury: </strong>
                240 cm,
              </li>
              <li>
                <strong>Przekątna: </strong> 676,75 cm,
              </li>
              <li>
                <strong>Typ drewna: </strong>C24 – iglaste,
              </li>
              <li>
                <strong>Ilość modułów głównych: </strong>8 szt,
              </li>
              <li>
                <strong>Ilość modułów legarowych: </strong>8 szt,
              </li>
              <li>
                <strong>Ilość modułów łącznikowych: </strong>18 szt,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 1: </strong> 2x dach B,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 2: </strong> 3x podłoga V,
              </li>
              <li>
                <strong>Wyposażenie opcjonalne 3: </strong> 2x s.diagonalna,
              </li>
            </ul>
          </CostLi>
          <h4>CENA KOMPLETU: 32 000,00 zł brutto</h4>
        </CostWrapper>
      );
    case 'create':
      return (
        <CostWrapper>
          <h3>Lista elementów:</h3>
        </CostWrapper>
      );
    default:
      return (
        <CostWrapper>
          <h3>Lista elementów:</h3>
        </CostWrapper>
      );
  }
};

export default ConfigModel;

const CostWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 1rem 0 0 0;
  border: 2px solid;
  height: 60vh;
  position: fixed;
  bottom: 20px;
  width: 25rem;
  right: 20px;
  z-index: 10000;
  overflow-y: auto;
  padding: 1rem;
`;

const CostLi = styled.div`
  margin-bottom: 30px;
  line-height: 35px;
`;
