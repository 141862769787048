import { DefaultTheme } from 'styled-components';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

// all breakpoints
export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

// style settings for light version
export const lightTheme: DefaultTheme = {
  body: '#ffffff',
  textColors: {
    primary: '#000000',
    secondary: '#ffffff',
  },
  listItemColor: '#ffffff',
  actionColors: {
    primary: '#6098f3',
    create: '#64cd94',
    delete: '#ea374d',
    lightDelete: 'rgba(234, 55, 77, 0.1)',
    closeModal: '#bdc3cf',
  },
  device,
};

export const darkTheme: DefaultTheme = {
  body: '#f5f6fb',
  textColors: {
    primary: '#000000',
    secondary: '#ffffff',
  },
  listItemColor: '#ffffff',
  actionColors: {
    primary: '#6098f3',
    create: '#64cd94',
    delete: '#ea374d',
    lightDelete: 'rgba(234, 55, 77, 0.1)',
    closeModal: '#bdc3cf',
  },
  device,
};
